import img from "../../images/other_services/ritrutturazioni_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Ristrutturazioni " prepageName="Servizio" postpageName="Ristrutturazione chiavi in mano" />
      <Service
        title="RISTRUTTURAZIONE"
        text="Vuoi un servizio di ristrutturazione?
        Pensiamo a tutto noi, ti sgraviamo da ogni responsabilità. 
        Inoltre, se vorrai usufruire del servizio chiavi in mano, ti dovrai concentrare solo sui tuoi desideri.
        La ristrutturazione non è semplice, assume diverse forme; dal progetto, la documentazione tecnica, la gestione degli operai, la scelta dei materiali…"
        img={img}
      >
      <br />
      <br />
      <p style={{color:"#000"}}>Qualcosa che non puoi fare da solo senza avere un grosso carico di stress e preoccupazioni. 
        Per questo ci siamo noi, non solo ristrutturiamo e facciamo tutto al posto tuo, ma ti accompagniamo verso la strada più semplice. 
        Abbiamo un team di professionisti specializzati, non solo operai, ma anche architetti.</p>
      <br />
      <br />
      <lu>
        <li>Con noi di Manutenpul potrai avere la casa o azienda o ufficio dei tuoi sogni.</li>
        <li>Niente preoccupazioni, solo desideri e relax</li>
        <li>Ci occupiamo noi delle pratiche amministrative e legali</li>
        <li>Ti guideremo nella scelta dei materiali per la tua casa, ufficio o azienda</li>
        <li>Se ti affidi a Manutenpul risparmierai tanto tempo</li>
        <li>Noi di Manutenpul siamo disponibili per ogni tua esigenza, per creare un abito cucito su misura.</li>
        <li>Dubbi se puoi usufruire della ristrutturazione casa 110, ristrutturazione casa bonus e ristrutturazione agevolazioni fiscali? Contattaci per una consulenza gratuita</li>
      </lu>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
